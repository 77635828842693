<template>
  <div>
    <label v-if="label" class="block mb-1 text-sm font-medium text-gray-700">{{ label }}</label>
    <label :for="id" class="w-full block">
      <div class="flex rounded-md shadow-sm overflow-hidden">
        <div class="relative flex items-stretch flex-grow focus-within:z-10">
          <div :style="`width: ${compressionProgress}%`" class="absolute transition-all duration-300 top-0 left-0 h-full bg-green-50"></div>
          <input :value="image.name" readonly type="text" class="relative bg-transparent focus:ring-ifgreen focus:border-ifgreen block w-full rounded-none rounded-l-md sm:text-sm border-gray-300" :placeholder="placeholder" />
        </div>
        <div class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-ifgreen focus:border-ifgreen">
          <CameraIcon v-if="!compressing" class="h-6 w-6 text-gray-400" aria-hidden="true" />
          <CogIcon v-else class="h-6 w-6 text-gray-400 animate-spin" aria-hidden="true" />
        </div>
      </div>
    </label>
    <input
      @change="onFileChange"
      class="hidden"
      :id="id"
      type="file"
      accept="image/*"
    >
  </div>
</template>

<script>
  import { CameraIcon, CogIcon } from '@heroicons/vue/24/outline'
  import watermark from 'watermarkjs'

  export default {
    components: {
      CameraIcon,
      CogIcon
    },
    emits: ['update:modelValue'],
    props: {
      id: String,
      label: String,
      modelValue: Object,
      placeholder: String,
      watermarkText: {
        type: String,
        default: ''
      },
    },
    data () {
      return {
        image: {
          name: '',
          file: null
        },
        compressing: false,
        compressionProgress: 0
      }
    },
    methods: {
      async onFileChange (e) {
        let files = e.target.files || e.dataTransfer.files
        if (!files.length) return
        const imageFile = files[0]
        this.image.name = imageFile.name
        this.createImage(imageFile)
      },
      createImage (file) {
        this.compressing = true
        watermark([file], {type: 'image/jpeg', encoderOptions: 0.6}).dataUrl(
          watermark.text.lowerRight(this.watermarkText, '100px Josefin Slab', '#fff', 0.5)
        ).then((img) => {
          this.compressionProgress = 100
          this.compressing = false
          this.image.file = img
          this.$emit('update:modelValue', this.image)
        })
      }
    },
    watch: {
      modelValue: {
        immediate: true,
        deep: true,
        handler () {
          if (this.modelValue) this.image = this.modelValue
        }
      },
    }
  }
</script>
